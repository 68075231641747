<template>
    <v-main :class="$route.meta.className || ''">
        <app-transition>
            <router-view :key="$route.fullPath"/>
        </app-transition>
        <bottom-bar :active="$route.meta.bottomBar"/>
    </v-main>
</template>

<script>
import BottomBar from '@/components/app/navigation/BottomBar';
import {mapState} from "vuex";
import {closeFullScreen, isInFullScreen, openFullScreen, isSafari} from "@/helpers/functions";

export default {
    name: "Default",
    head: {
        meta: [
            {
                name: "mobile-web-app-capable",
                content: "yes"
            },
            {
                name: "apple-mobile-web-app-title",
                content: "Noodzly"
            }
        ]
    },
    components: {
        BottomBar
    },
    computed: {
        ...mapState({
            step: state => state.story.isShowCameraScreen.step,
        })
    },
    watch: {
        step(value) {
            if (typeof value !== 'undefined' && (value === 0 || value === 1)) {
                if (!isInFullScreen()) {
                    openFullScreen()
                }
            } else {
                if (isInFullScreen()) {
                    closeFullScreen()
                }
            }
        },
    },
    mounted() {
        //Hack for lock portrait orientation for Safari browser
        if (isSafari()) {
           // document.documentElement.classList.add('rotateHtml');
        }
    }
}
</script>
<style>
@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    .rotateHtml {
        width: 100vh;
        height: auto;
        min-height: 100vw;
        transform: rotate(-90deg);
        transform-origin: left top;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: black;
        padding: 0;
        margin: 0;
    }
}
</style>
